import React, { useState } from 'react';
import axios from "axios"
import config from '../../config/config'

  const CreateTable = () => {

  const [nombreTabla, setNombreTabla] = useState("");
  const [columnas] = useState ({
    POS: 'INT',
    POS_CAT: 'INT',
    DORSAL: 'INT',
    NOMBRE: 'VARCHAR(100)',
    GENERO: 'VARCHAR(100)',
    CATEGORIA: 'VARCHAR(100)',
    PROCEDENCIA: 'VARCHAR(100)',
    TIEMPO: 'VARCHAR(100)'
  })

  /**Crear tabla */
  const handleNombreTablaChange = (event) => {
    setNombreTabla(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const nombreTablaReemplazado = nombreTabla.replace(/\s+/g, '_');
    try {
    const response = await axios.post(`${config.API_URL}/crearTabla`, {nombreTabla: nombreTablaReemplazado, columnas});
      alert(response.data);
      
      }catch(error) {
        alert('Error al intentar crear la tabla');
      }
  };

  return (
    <div className='container'>
        
      <form className='form-inline' onSubmit={handleSubmit}>
        <label className='form-label text-light'>
          Nombre de la carrera:
          <input type="text" className='form-control' value={nombreTabla} onChange={handleNombreTablaChange} />
        </label>
        <button type="submit" className='btn btn-primary'>Crear carrera</button>
      </form>
    
    </div>
  );
};

export default CreateTable;

