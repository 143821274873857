import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faSquareFacebook} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-top">
        
        <div className="footer-contact" style={{flex:1, marginTop: 20}}>
          <h4>Contacto</h4>
          <p>Teléfono: +549 260 459-9048</p>
          <p>Email: informes@esfuerzodeportivo.com</p>
        </div>
        <div className="footer-social"  style={{flex:1, marginTop: 20}}>
          <h4>Redes Sociales</h4>
          <ul className='footer-redes' type='button' style={{flexDirection: 'column'}}>
            <a href="http://www.facebook.com/esfuerzo.deportivo.1/">
              <FontAwesomeIcon icon={faSquareFacebook} style={{marginRight:10}} color='white'/>
            </a>
            <a href="https://www.instagram.com/esfuerzo.deportivo/">
              <FontAwesomeIcon icon={faInstagram} style={{marginRight:10}} color='white'/>
            </a>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Esfuerzo Deportivo. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
