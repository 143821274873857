import React, { useState } from 'react';
import logo from '../images/LogoED.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="container">
      <nav className="container mx-auto flex flex-wrap items-center justify-between p-2">
        
        {/* Logo */}
        <NavLink to="/home">
          <img src={logo} className="h-12" alt="No Encontrada" />
        </NavLink>

        {/* Toggle Button for small screens */}
        <button
          type="button"
          className="text-white lg:hidden block focus:outline-none"
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </span>
        </button>

        {/* Navbar Links */}
        <div
          id="navbarCollapse"
          className={`w-full lg:flex lg:w-auto lg:space-x-4 text-white ${isOpen ? 'block' : 'hidden'} lg:block`}
        >
          <ul className="flex flex-col lg:flex-row lg:space-x-4 space-y-2 lg:space-y-0">
            <li>
              <NavLink
                to="/home"
                className="hover:bg-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium text-center"
              >
                Inicio
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Carreras"
                className="hover:bg-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium text-center"
              >
                Carreras
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tutiempo"
                className="hover:bg-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium text-center"
              >
                Tu Tiempo
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Inscripciones"
                className="hover:bg-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium text-center"
              >
                Inscripciones
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Nosotros"
                className="hover:bg-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium text-center"
              >
                Nosotros
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
