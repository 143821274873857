import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import config from '../../../config/config.js';

const DownloadExcel = ({raceName}) => {
  const [columns, setColumns] = useState([]); // Columnas disponibles
  const [selectedColumns, setSelectedColumns] = useState([]); // Columnas seleccionadas
  const [data, setData] = useState([]); // Datos de la tabla
  const [selectAll, setSelectAll] = useState(true);

  // Cargar los datos desde el backend
  useEffect(() => {
    const fetchData = async () => {
      if (!raceName) return;

      try {
        const response = await fetch(`${config.INSCRIPTION_URL}/api/download/${raceName}`);
        const jsonData = await response.json();
        
         // Extraer las columnas dinámicamente del primer objeto (si existe)
         if (jsonData.length > 0) {
          const dynamicColumns = Object.keys(jsonData[0]);
          setColumns(dynamicColumns);
          setSelectedColumns(dynamicColumns); // Seleccionar todas por defecto
        }
        setData(jsonData);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();
  }, [raceName]);

  // Manejar cambios en la selección de columnas
  const handleColumnChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedColumns((prev) => [...prev, value]);
    } else {
      setSelectedColumns((prev) => prev.filter((column) => column !== value));
    }
  };

  // Manejar el botón "Seleccionar/Deseleccionar Todo"
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns([]); // Deseleccionar todas
    } else {
      setSelectedColumns(columns); // Seleccionar todas
    }
    setSelectAll(!selectAll); // Alternar el estado
  };

  // Generar y descargar el archivo Excel
  const handleDownload = () => {
    if (data.length === 0) {
      alert('No hay datos disponibles para descargar.');
      return;
    }

    // Obtener la fecha actual y formatearla como DD-MM-YYYY
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}/${
      (currentDate.getMonth() + 1).toString().padStart(2, '0')
    }/${currentDate.getFullYear()}`;

    // Filtrar los datos según las columnas seleccionadas
    const filteredData = data.map((row) =>
      selectedColumns.reduce((obj, key) => {
        // Convertir fechas al formato DD/MM/YYYY si es una fecha
        if (key === 'DateOfBirth' && row[key]) {
          const date = new Date(row[key]);  
          obj[key] = `${date.getDate().toString().padStart(2, '0')}/${
            (date.getMonth() + 1).toString().padStart(2, '0')
          }/${date.getFullYear()}`;
        } else {
          obj[key] = row[key];
        }
        return obj;
      }, {})
    );

    // Convertir los datos filtrados a una hoja de Excel
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Inscriptos');

    // Generar el archivo y descargarlo
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${formattedDate.replace(/\//g, '-')}_Inscriptos_${raceName}.xlsx`);
  };

  return (
    <div className="container" style={{ marginTop: '70px' }}>
      <h3>Selecciona las columnas que deseas descargar</h3>
      <Button
        variant="contained"
        color="error"
        onClick={handleSelectAll}
        sx={{ marginTop: 5, marginBottom: '20px' }}
      >
        {selectAll ? 'Deseleccionar Todo' : 'Seleccionar Todo'}
      </Button>
      <br></br>
        {columns.map((column) => (
          <FormControlLabel
            key={column}
            control={
              <Checkbox
                checked={selectedColumns.includes(column)}
                onChange={handleColumnChange}
                value={column}
                color="error"
              />
            }
            label={column}
          />
        ))}
        <br></br>
      <Button
        variant="contained"
        color="error"
        onClick={handleDownload}
        sx={{ marginTop: '20px' }}
      >
        Descargar Excel
      </Button>
    </div>
  );
};

export default DownloadExcel;