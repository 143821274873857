// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA5pRGrCv5F-1GnIqcDl-utUs38p8IVfYE",
  authDomain: "inscriptions-ed.firebaseapp.com",
  projectId: "inscriptions-ed",
  storageBucket: "inscriptions-ed.appspot.com",
  messagingSenderId: "1070518379346",
  appId: "1:1070518379346:web:a6e30e3870832f5313908b"
};

// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);
export default appFirebase;