import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import axios from 'axios';
import config from '../../config/config';
import Modal from './Modal';
import { useParams } from 'react-router-dom';

// Filtro global
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <input
      value={globalFilter || ''}
      onChange={e => setGlobalFilter(e.target.value || undefined)}
      placeholder="Numero, nombre, categoria"
      style={{
        padding: '8px',
        borderRadius: '4px',
        width: '100%',
        boxSizing: 'border-box',
        color: "black"
      }}
    />
  );
};

const DataTable = () => {
  const [data, setData] = useState([]);
  const [genderFilter, setGenderFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [visibleCount] = useState(5);
  const [showAllGenders, setShowAllGenders] = useState([]);
  const [selectedRunner, setSelectedRunner] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const { imageName } = useParams();

  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        const storedImageName = localStorage.getItem('imageName');
        const storedImageUrl = localStorage.getItem('imageUrl'); // Obtener la URL de la imagen de localStorage

        if (!storedImageName || !storedImageUrl) {
          console.error('imageName o imageUrl no encontrado');
          return;
        }

        setImageUrl(storedImageUrl);

        const response = await axios.get(`${config.API_URL}/datos?imageName=${storedImageName}`);
        setData(response.data);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    obtenerDatos();
  }, [imageName]);

  

  const columns = React.useMemo(() => [
    { Header: 'POS', accessor: 'POS' },
    { Header: 'POS_CAT', accessor: 'POS_CAT' },
    { Header: 'DORSAL', accessor: 'DORSAL' },
    { Header: 'NOMBRE', accessor: 'NOMBRE' },
    { Header: 'CATEGORIA', accessor: 'CATEGORIA' },
    { Header: 'PROCEDENCIA', accessor: 'PROCEDENCIA' },
    { Header: 'TIEMPO', accessor: 'TIEMPO' }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
  }, useGlobalFilter, usePagination);

  const filteredGlobalData = React.useMemo(() => {
    return data.filter(item => {
      const stringValue = Object.values(item).join(' ').toLowerCase();
      return stringValue.includes(globalFilter ? globalFilter.toLowerCase() : '');
    });
  }, [data, globalFilter]);

  // Luego aplicamos los filtros de género y categoría
  const filteredData = React.useMemo(() => {
    return filteredGlobalData.filter(item =>
      (genderFilter === '' || item.GENERO === genderFilter) &&
      (categoryFilter === '' || item.CATEGORIA === categoryFilter)
    );
  }, [genderFilter, categoryFilter, filteredGlobalData]);

  const visibleData = React.useMemo(() => {
    const groupedByGender = filteredData.reduce((acc, item) => {
      if (!acc[item.GENERO]) {
        acc[item.GENERO] = [];
      }
      if (showAllGenders.includes(item.GENERO) || acc[item.GENERO].length < visibleCount) {
        acc[item.GENERO].push(item);
      }
      return acc;
    }, {});

    const combinedData = [];
    Object.keys(groupedByGender).forEach(gender => {
      combinedData.push({ separator: true, gender });
      combinedData.push(...groupedByGender[gender]);
      if (!showAllGenders.includes(gender) && groupedByGender[gender].length === visibleCount) {
        combinedData.push({ showAllButton: true, gender });
      }
    });

    return combinedData;
  }, [filteredData, visibleCount, showAllGenders]);

  const handleRowClick = (runner) => {
    setSelectedRunner(runner);
    setIsModalOpen(true);
  };

  const uniqueGenders = React.useMemo(() => {
    const genders = new Set(data.map(item => item.GENERO));
    return [...genders].sort();
  }, [data]);

  const uniqueCategories = React.useMemo(() => {
    const filteredByGender = genderFilter ? data.filter(item => item.GENERO === genderFilter) : data;
    const categories = new Set(filteredByGender.map(item => item.CATEGORIA));
    return [...categories].sort();
  }, [data, genderFilter]);



  return (
    <div className='container-table'>
      <div style={{ width: '100%', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={imageUrl} alt="Banner" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
      </div>
  
    <div>
      <label htmlFor="globalFilter" style={{ padding: '10px'}}>Buscar corredor:</label>
      <GlobalFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}/>
    </div>

{visibleData.length === 0 ? (
  <p>Tabla de posiciones</p>
) : (
  <>
  <br></br>
    <div className='filter-container'>
      <div className='filter-group'>
        <label htmlFor="genderFilter">Filtrar por Género: </label>
        <select
          id="genderFilter"
          value={genderFilter}
          onChange={e => {
            setGenderFilter(e.target.value);
            setCategoryFilter('');
            setShowAllGenders([]);
          }}
        >
          <option value=''>Todos</option>
          {uniqueGenders.map((gender, i) => (
            <option key={i} value={gender}>
              {gender}
            </option>
          ))}
        </select>
      </div>

      <div className='filter-group'>
        <label htmlFor="categoryFilter">Filtrar por Categoría: </label>
        <select
          id="categoryFilter"
          value={categoryFilter}
          onChange={e => setCategoryFilter(e.target.value)}
        >
          <option value=''>Todas</option>
          {uniqueCategories.map((category, i) => (
            <option key={i} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
    </div>
          <br />
          <br />
          <div>
            <table className='table' {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {visibleData.map((row, i) => {
                  if (row.separator) {
                    return (
                      <tr className='row-sep' key={`separator-${i}`}>
                        <td colSpan={columns.length} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          {row.gender}
                        </td>
                      </tr>
                    );
                  } else if (row.showAllButton) {
                    return (
                      <tr key={`show-all-${row.gender}`}>
                        <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                          <button onClick={() => setShowAllGenders(prev => [...prev, row.gender])}>
                            Mostrar todos los {row.gender}
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    const rowIndex = rows.findIndex(r => r.original === row);
                    if (rowIndex !== -1) {
                      const rowInstance = rows[rowIndex];
                      prepareRow(rowInstance);
                      return (
                        <tr {...rowInstance.getRowProps()} onClick={() => handleRowClick(rowInstance.original)}>
                          {rowInstance.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          ))}
                        </tr>
                      );
                    }
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        </>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        runner={selectedRunner}
        imageUrl={imageUrl}  // Pasar la URL de la imagen al modal
      />
    </div>
  );
};
/** */
export default DataTable;
