import React from 'react'

const ErrorNotFound = () => {
  return (
    <div className="container" style={{color: 'white', fontFamily: 'Monserat, sans-serif'}}>
      <center>
        <br/>
        <h1 style={{fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>Sobre Nosotros</h1>
        <p>Somos una empresa de Mendoza, dedicada a la organización
integral de eventos deportivos, sociales y solidarios. contamos con una
gran variedad de opciones para que su evento seo único e inolvidable.
Nuestros valores humanos sumados a los conocimientos técnicos y
profesionalismo adquiridos en nuestros 17 años de trayectoria, nos
distinguen y se ven reflejados en la satisfacción de nuestros clientes.
La celebración de un evento corporativo ante clientes, autoridades,
medios de comunicación y, en definitiva, ante la sociedad en general, es
sin duda una magnifica oportunidad para potenciar la imagen y la marca
de la empresa.
Es por ello que contamos con el material humano y tecnológico para
llevarlo a cabo.
Nuestro trabajo abarca entre otras áreas las carreras deportivas, clínicas
deportivas, eventos solidarios, work day, jornadas de integración, entre
otras.</p>
      </center>
    </div>
  )
}

export default ErrorNotFound
