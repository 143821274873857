import React from 'react'
import Banner from './Banner';
import ProxRace from './ProxRace';
import Footer from './Footer';
import Marketing from './Marketing';


const Home = () => {
  return (
    <>
      <Banner/>
      <ProxRace/>
      <Marketing/>
      <Footer/>
    </>
  )
}

export default Home
