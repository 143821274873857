import React, { useState } from 'react';
import { Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import Sidebar from '../User/Sidebar';
import Header from '../User/Header';
import MainContent from './MainContent';
import InscriptosList from './InscriptosList';
import DownloadExcel from './DownloadExcel';
import DiscountCode from './DiscountCode';
import { useLocation } from 'react-router-dom';

export default function UserDash() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState('MainContent'); // Estado para manejar el componente activo
  const isMobile = useMediaQuery('(max-width:600px)');
  const location = useLocation(); // Obtener la ubicación
  const raceName = location.state?.raceName;

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleComponentChange = (component) => {
    console.log('Componente activo:', activeComponent);

    setActiveComponent(component);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {(!isMobile || sidebarOpen) && (
        <Sidebar onComponentChange={handleComponentChange} closeSidebar={toggleSidebar} />
      )}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Header toggleSidebar={toggleSidebar} />
        {activeComponent === 'MainContent' && <MainContent raceName={raceName} />}
        {activeComponent === 'InscriptosList' && <InscriptosList raceName={raceName} />}
        {activeComponent === 'DownloadExcel' && <DownloadExcel raceName={raceName} />}
        {activeComponent === 'DiscountCode' && <DiscountCode raceName={raceName} />}
        <Toolbar />
      </Box>
    </Box>
  );
}
