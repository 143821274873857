import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Importa useLocation para obtener los parámetros de la URL
import { Box, Typography, Button } from '@mui/material';
import LogoInscripciones from '../../images/EDI.png';
import axios from 'axios';
import config from '../../config/config.js'

const AuthVendedores = () => {
  const location = useLocation(); // Hook para acceder a los parámetros de la URL

  useEffect(() => {
    console.log("Location search:", location.search); // Verifica que capture la URL
  
    const params = new URLSearchParams(location.search); // Obtener los parámetros de la URL
    const authorizationCode = params.get('code'); // Capturar el código de autorización
    const state = params.get('state');
  
    if (authorizationCode && state) {
      console.log('Authorization Code:', authorizationCode);
      console.log('State', state);
  
      // Hacer una solicitud a tu backend para enviar el código de autorización
      /*axios.post('https://aade-179-63-240-56.ngrok-free.app/api/mercadopago/callback', { code: authorizationCode, state })*/
      axios.post(`${config.INSCRIPTION_URL}/mercadopago/callback`, { code: authorizationCode, state })
        .then(response => {
          console.log('Tokens guardados:', response.data);
        })
        .catch(error => {
          console.error('Error al guardar los tokens:', error);
        });
    } else {
      console.error('No se encontró el código de autorización o el state en la URL');
    }
  }, [location]);

  return (
    <div className="white-background">
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          padding: { xs: 2, md: 4 },
          maxWidth: '100%',
          margin: 'auto',
          fontFamily: 'Arial, sans-serif',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 4,
          }}
        >
          <Box
            component="img"
            src={LogoInscripciones}
            alt="logo"
            sx={{
              width: { xs: '60%', md: '15%' },
              borderRadius: 2,
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#f5f5f5',
              padding: { xs: 2, md: 4 },
              borderRadius: 2,
              maxWidth: '100%',
              fontFamily: 'Arial, sans-serif',
              boxShadow: '0px 1px 10px black',
              marginBottom: { xs: 2, md: 0 },
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: 'bold', marginBottom: 3, color: 'grey' }}
            >
              Tu panel de inscripciones está listo para usar!
            </Typography>

            <Button
              variant="contained"
              color="error"
              fullWidth
              sx={{ marginTop: 3 }}
              onClick={() => {
                // Redirigir al vendedor a la autorización de Mercado Pago
                window.location.href = 'https://auth.mercadopago.com.ar/authorization?client_id=7913551262407125&response_type=code&platform_id=mp&state=maraton_internacional_de_san_juan_2025&redirect_uri=https://esfuerzodeportivosr.com.ar/authvendedores';
              }}
            >
              Ingresar
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AuthVendedores;
