import React, { useState } from 'react';
import Menu from './Menu.jsx'


const Login = () => {

    const [miLogin, setMiLogin] = useState("false");
    const [usu, setUsu] = useState("");
    const [pas, setPas] = useState("");
    
    function iniciarSesion(e){
        e.preventDefault();
        var txtusu = document.getElementById("txtusu").value;
        var txtpas = document.getElementById("txtpas").value;
        if(txtusu.length===0 || txtpas.length===0){
            alert("Complete los datos!!!");
        }else{
            if(usu === "AdminED" && pas === "Timers2024"){
                setMiLogin("true");
                document.getElementById("form_login").style.display ="none";
            }else{
                setMiLogin("false");
                alert("Error de usuario o contraseña");
                document.getElementById("txtusu").value="";
                document.getElementById("txtpas").value="";
                document.getElementById("txtusu").focus();
            }
        }
    }
    

  return (
    
    <div className="container bg-dark text-white" style={{padding:20, borderRadius: '1rem', maxWidth:'800px'}}>
        
    <form id="form_login">
        <div>
            <h1 style={{color:"white", textalign:"center"}}>LOGIN</h1>
            <label type='text'><strong>Username</strong></label>
            <input type="text" id="txtusu" style={{textAlign:"center"}} className="form-control" onChange={(e)=>setUsu(e.target.value)} required/>
        </div>
        <br></br>
        <div>
            <label htmlFor="txtpas"><strong>Password</strong></label>
            <input type="password" id="txtpas" style={{textAlign:"center"}} className="form-control" onChange={(e)=>setPas(e.target.value)} required/>
        </div><br/>
        <input type="submit" className="btn btn-primary" value="Login" onClick={iniciarSesion}/>
    </form>

    { miLogin === "true" && <Menu/>}

</div>

  )
}

export default Login