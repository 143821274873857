import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import config from '../../../config/config.js';

const DiscountCode = ({ raceName }) => {
  const [code, setCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!code || !discountPercentage || !expirationDate) {
      setMessage('Todos los campos son obligatorios');
      return;
    }

    try {
      const response = await axios.post(`${config.INSCRIPTION_URL}/api/discounts`, {
        raceName,
        code,
        discountPercentage: parseFloat(discountPercentage),
        expirationDate,
      });

      setMessage(response.data.message);
      setCode('');
      setDiscountPercentage('');
      setExpirationDate('');
    } catch (error) {
      console.error('Error al crear el código de descuento:', error);
      setMessage('Hubo un error al crear el código de descuento.');
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{marginTop: '70px'}}>
        Crear Código de Descuento
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Código"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Porcentaje de Descuento (%)"
          type="number"
          value={discountPercentage}
          onChange={(e) => setDiscountPercentage(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Fecha de Expiración"
          type="date"
          value={expirationDate}
          onChange={(e) => setExpirationDate(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <Button type="submit" variant="contained" color="error" fullWidth>
          Crear Código
        </Button>
      </form>
      {message && (
        <Typography variant="body1" color="error" marginTop="16px">
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default DiscountCode;
