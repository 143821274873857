import React from 'react';
import logo from '../../images/LogoED.png';

const Modal = ({ isOpen, onClose, runner, imageUrl }) => {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  if (!isOpen || !runner) return null;

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <div className="modal-body">
          {imageUrl && (
            <div style={{ width: '100%', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={imageUrl} alt="Foto de la carrera" className="modal-image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
            </div>
          )}
          <h1><strong></strong> {runner.NOMBRE}</h1>
          <h1 style={{ background: 'red' }}><strong></strong> {runner.TIEMPO}</h1>
          <h3><strong>Dorsal:</strong> {runner.DORSAL}</h3>
          <h2><strong>Pos. Gral:</strong> {runner.POS}</h2>
          <h2><strong>Pos. Categoría:</strong> {runner.POS_CAT}</h2>
          <h3><strong>Género:</strong> {runner.GENERO}</h3>
          <h3><strong>Categoría:</strong> {runner.CATEGORIA}</h3>
          <img src={logo} alt="Foto de la carrera" className="modal-image" />
        </div>
      </div>
    </div>
  );
};

export default Modal;
