import React, {useState} from "react";
import { Box, Typography, Grid, Card, CardContent, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import img1 from "../DesafioPuntaNegra2025/PuntaNegra.jpg";
import reglamento from "../DesafioPuntaNegra2025/REGLAMENTO.pdf"
import concentimiento from "../DesafioPuntaNegra2025/CONSENTIMIENTO.pdf"

const DesafioPuntaNegra2025 = () => {
  const navigate = useNavigate();
  const [codigo, setCodigo] = useState('');
  const [error, setError] = useState(false);
  const isSoldOut = true; // Cambia esto dinámicamente según la disponibilidad.


  // Función para validar el código y redirigir si es correcto
const validarCodigo = () => {
  const codigoValido = "100K-LZ9514"; // Reemplaza con tu lógica de validación (puedes usar el backend aquí)

  if (codigo === codigoValido) {
    // Redirigir usando handleInscribirse como otras distancias
    handleInscribirse(
      "Desafio Punta Negra 2025",
      "100K",
      "Periodo lanzamiento",
      "130.000",
      img1
    );
  } else {
    setError(true); // Mostrar mensaje de error si el código es incorrecto
  }
};

  const handleInscribirse = (raceName, distance, periodoInscripcion, price, image) => {
    const numericPrice = parseFloat(price.replace(/\./g, "").replace(",", ".")); // Convierte '20.000,00' a 20000.00
    const priceTax = numericPrice * 0.0639; // Calcula el 6.39% del precio original
    // Redirige al componente Forms con los datos seleccionados
    navigate(`/${raceName}/forms`, {
      state: { distance, periodoInscripcion, price: numericPrice, priceTax: priceTax.toFixed(2), image },
    });
  };
  

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        borderRadius: 2,
        maxWidth: "90%",
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontFamily: "Arial, sans-serif",
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: "100%",
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: "300px",
        }}
      />

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Información de la Carrera
      </Typography>
      <Typography variant="body1" sx={{textAlign: "center"}}>
        La 13ª edición del Desafío Punta Negra se celebrará entre el 22 y 25 de mayo de 2025, en la Provincia de San Juan en un escenario natural incomparable, donde la montaña, la naturaleza y el esfuerzo humano se combinan para ofrecer una experiencia única y transformadora.
        <br></br><br></br>
        Con distancias y categorías para todos, El Desafío Punta Negra se ha convertido en un evento para toda la familia y para corredores de todos los niveles. Desde aquellos que se inician en el trail running y buscan su primer desafío, hasta los ultratrailers profesionales que quieren poner a prueba su resistencia y habilidades en circuitos de altísimo nivel. Además, las categorías Kids brindan a los más pequeños la oportunidad de participar en una experiencia única desde temprana edad.
        <br></br><br></br>
        Los participantes podrán sumar puntos ITRA en las distancias 100 km, 75 km y 42 km, lo que les permitirá obtener clasificación para algunas de las competiciones más prestigiosas a nivel internacional.
        <br></br><br></br>
        Nuevos circuitos, más emoción!
        <br></br><br></br>
        Este año, El Desafío Punta Negra llega con nuevos y desafiantes circuitos para todas las distancias, lo que le dará un toque renovado a la carrera siguiendo con su sello que lo caracteriza de circuitos técnicos y pasajes espectaculares. Las distancias disponibles incluyen:
        <br></br>
            •	100 km, 75 km y 42 km - Viernes 23 y Sábado 24 de mayo
        <br></br>   
            •	25 km, 12 km y 5 km - Domingo 25 de mayo
      </Typography>
   
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        DOCUMENTACIÓN IMPORTANTE
      </Typography>
      
      {/* Enlace de vista previa del PDF */}
      <Button
        variant="contained"
        color="primary"
        href={reglamento} // Usa `href` en lugar de `src` para abrir el PDF
        target="_blank" // Abre el PDF en una nueva pestaña
        rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
        sx={{
          marginTop: 2,
          marginRight: 5,
          backgroundColor: "red",
          ":hover": { backgroundColor: "darkred", color: "white" },
        }}
      >
        Reglamento
      </Button>

      <Button
        variant="contained"
        color="primary"
        href={concentimiento} // Usa `href` en lugar de `src` para abrir el PDF
        target="_blank" // Abre el PDF en una nueva pestaña
        rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
        sx={{
          marginTop: 2,
          backgroundColor: "red",
          ":hover": { backgroundColor: "darkred", color: "white" },
        }}
      >
        CONSENTIMIENTO
      </Button>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Info Inscripciones
      </Typography>
      <Typography variant="body1" sx={{textAlign: "left"}}>
        Las inscripciones se pueden realizar ON LINE o personalmente en Rustik Aventura, Hipólito Yrigoyen 41 sur, provincia de San Juan (Todos los corredores RESIDENTES de San Juan gozarán de un descuento especial, pagando en EFECTIVO).
        <br></br><br></br>
        PRECIO LANZAMIENTO: el 01/12/24 se hará la apertura oficial, y primera etapa de inscripciones, con un precio Promoción solo para los 500 primeros inscriptos.
        Este valor corresponde al PRECIO TOTAL y en un solo pago (no debiendo abonar ninguna cuota posterior extra). Es un VALOR UNICO sin acceso a descuentos de teams ni residentes.
        <br></br><br></br>
        INSCRIPCIONES POR ETAPAS: Una vez agotado el cupo del precio lanzamiento comenzaran las inscripciones por etapas, habilitando CUPOS de 500 lugares y precios determinado en cada una de ellas.
        <br></br><br></br>
        -	Para acceder a descuento para teams enviar mail a desafiopuntanegra@adventurepro.com.ar
        <br></br>
        -	Para acceder a descuento RESIDENTE dirigirse a RUSTIK abonando en efectivo.
        <br></br><br></br>
        Al momento de inscribirse el participante puede optar por las siguientes dos opciones:
        A. Abonar el 100% del valor de la inscripcion.
        B. Abonar en dos cuotas: el 50% del valor de la inscripción y el 50% restante en EFECTIVO el día de la acreditación y retiro de Kit.  
        <br></br><br></br>
        El pago del valor de la inscripción (ya sea el 50% o 100%) NO es rembolsable en caso de la no participación del corredor ya que es una seña y se rige por el código 1059 del Cod. Civil y comercial.
        <br></br><br></br>
        En caso de no poder participar se contempla, hasta el 25/04/25, las siguientes opciones:
        <br></br> 
        a. Transferir el valor abonado a un nuevo participante (no debe estar inscripto), haciendo un cambio de titularidad.
        <br></br> 
        b. Pasar el valor abonado como CREDITO para la edición del 2026.
        <br></br><br></br>
        En ambos casos se deberá abonar una multa administrativa de $5.000 y se debe solicitar por mail a desafiopuntanegra@adventurepro.com.ar
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Inscripciones
      </Typography>

      {/*Aqui arrancan las card de las distancias*/}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                100K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$130.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              <Button
                variant="contained"
                color="error"
                href="https://docs.google.com/forms/d/13DZCeM2CZiezr5J0iPMac_d2CkuLYwnZb3WU7ttPXpM/edit"
                target="_blank" // Abre el enlace en una nueva pestaña
                rel="noopener noreferrer" // Mejora la seguridad del enlace
                sx={{
                  backgroundColor: "red",
                  ":hover": { backgroundColor: "darkred", color: "white", marginBottom: 2 },
                }}
              >
                Formulario
              </Button>

              <Typography variant="h6">Si ya estás verificado, inscribete:</Typography>
          <TextField
            label="Código de verificación"
            variant="outlined"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
              setError(false); // Limpiar el mensaje de error
            }}
            sx={{ marginBottom: 2, width: '80%' }}
            error={error}
            helperText={error ? "Código incorrecto" : ""}
          />
          <Button
            variant="contained"
            color="error"
            onClick={() => validarCodigo()}
            sx={{
              backgroundColor: "red",
              ":hover": { backgroundColor: "darkred", color: "white" },
            }}
          >
            Inscribirse
          </Button>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                75K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$98.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Desafio Punta Negra 2025",
                    "75K",
                    "Periodo lanzamiento",
                    "98.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
               {isSoldOut && (
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
              <Typography variant="h6">Cupos agotados</Typography>
              <Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>
              </Box>
              )}
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                42K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$80.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Desafio Punta Negra 2025",
                    "42K",
                    "Periodo lanzamiento",
                    "80.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
                        {isSoldOut && (
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
                <Typography variant="h6">Cupos agotados</Typography>
                <Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>
              </Box>
            )}
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                25K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$65.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Desafio Punta Negra 2025",
                    "25K",
                    "Periodo lanzamiento",
                    "65.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
                          {isSoldOut && (
                <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
                  <Typography variant="h6">Cupos agotados</Typography>
                  <Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>
                </Box>
              )}
                        </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                12K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$55.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Desafio Punta Negra 2025",
                    "12K",
                    "Periodo lanzamiento",
                    "55.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
                        {isSoldOut && (
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
                <Typography variant="h6">Cupos agotados</Typography>
                <Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>
              </Box>
            )}
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                5K
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$40.000</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              {/*<Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Desafio Punta Negra 2025",
                    "5K",
                    "Periodo lanzamiento",
                    "40.000",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>*/}
                        {isSoldOut && (
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
                <Typography variant="h6">Cupos agotados</Typography>
                <Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>
              </Box>
            )}
            </Box>
          </Card>
        </Grid>

        {/*<Grid item xs={12} sm={6} md={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 300,
              transition: "0.2s",
              boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
              borderRadius: 3,
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <CardContent>
              <Typography variant="h2" gutterBottom>
                Prueba mail
              </Typography>
              <Typography variant="h7" gutterBottom>
                Periodo lanzamiento
              </Typography>
              <Typography variant="h5">$10</Typography>
            </CardContent>
            <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleInscribirse(
                    "Desafio Punta Negra 2025",
                    "Prueba mail",
                    "Periodo lanzamiento",
                    "10",
                    img1
                  )
                }
              >
                Inscribirse
              </Button>
                        {isSoldOut && (
              <Box sx={{ textAlign: "center", backgroundColor: "rgba(255, 0, 0, 0.7)", color: "white", padding: 1 }}>
                <Typography variant="h6">Cupos agotados</Typography>
                <Typography variant="h6" sx={{fontSize: '0.8rem'}}>Proximamente nuevo periodo</Typography>
              </Box>
            )}
            </Box>
          </Card>
        </Grid>*/}

      </Grid>
    </Box>
  );
};

export default DesafioPuntaNegra2025;
