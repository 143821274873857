import React, { useEffect } from 'react';
import carrera1 from '../../images/Banner/page1.jpg';

const NonoTrailRun = () => {
  useEffect(() => {
    const initializeRRPublish = () => {
      console.log("RRPublish script loaded.");
      if (window.RRPublish) {
        const rrp = new window.RRPublish(document.getElementById("divRRPublish"), 296645, "results");
        rrp.ShowTimerLogo = true;
        rrp.ShowInfoText = false;
        console.log("RRPublish initialized.");
        
        // Debugging: Check if the content is actually loaded
        const contentElement = document.querySelector('.RRPublish-content');
        if (contentElement) {
          console.log("RRPublish content found:", contentElement.innerHTML);
        } else {
          console.log("RRPublish content not found.");
        }
      } else {
        console.error("RRPublish is not available on window.");
      }
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://my.raceresult.com/RRPublish/load.js.php?lang=en';
    script.async = true;
    script.onload = initializeRRPublish;
    
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      const rrPublishDiv = document.getElementById("divRRPublish");
      if (rrPublishDiv) {
        rrPublishDiv.innerHTML = ''; // Clear the div content when unmounting
      }
    };
  }, []);

  return (
    <div>
      <img src={carrera1} alt={'bannerinscription'} className='image-form' />
      <div id="divRRPublish" className="RRPublish"></div>
    </div>
  );
};

export default NonoTrailRun;
