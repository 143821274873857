import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from '../images/Carousel/descripcion.JPG'
import image2 from '../images/Carousel/Alquiler-infraestructura.JPG'
import image3 from '../images/Carousel/Arco-llegada.jpeg'
import image4 from '../images/Carousel/Alquiler-relojes.JPG'
import image5 from '../images/Carousel/Relojes.JPG'
import image6 from '../images/Carousel/Kiosk-consulta.JPG'
import image7 from '../images/Carousel/Kiosk.JPG'
import image8 from '../images/Carousel/Toma-tiempos.JPG'
import image9 from '../images/Carousel/Race-result.JPG'

const Marketing = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9
  ];

  return (
    <div className="container">
      <h1 className='prox-car'  style={{marginTop: 20}}>Logística</h1>
    <div className="image-carousel">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default Marketing;
