import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import CreateTable from './CreateTable';
import UpExcel from './UpExcel';
import UpLoad from './UpLoad';

const Menu = () => {
  const [create, setCreate] = useState('');
  const [excel, setExcel] = useState('');
  const [img, setImg] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  function cerrarSecion() {
    document.getElementById('caja_menu').style.display = 'none';
    document.getElementById('form_login').style.display = 'block';
    document.getElementById('txtusu').value = '';
    document.getElementById('txtpas').value = '';
    document.getElementById('txtusu').focus();
  }

  function op_crear() {
    setCreate('1');
    setExcel('0');
    setImg('0');
  }

  function op_excel() {
    setCreate('0');
    setExcel('1');
    setImg('0');
  }

  function op_img() {
    setCreate('0');
    setExcel('0');
    setImg('1');
  }

  return (
    <>
      <div id="caja_menu" className="text-left p-4">
        <strong className="text-2xl font-semibold">
          Usuario Esfuerzo Deportivo:
        </strong>

        <nav className="text-white mt-4">
          <div className="container mx-auto">
            <button
              className="lg:hidden px-4 py-2 text-white focus:outline-none"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="text-2xl">☰</span>
            </button>
            <div
              className={`lg:flex lg:items-center lg:justify-center ${isOpen ? 'block' : 'hidden'} lg:block`}
            >
              <div className="flex flex-col lg:flex-row lg:space-x-4">
                <NavLink
                  to=""
                  className="nav-link text-lg text-center py-2 hover:text-red-500"
                  onClick={op_crear}
                >
                  Agregar carrera
                </NavLink>
                <NavLink
                  to=""
                  className="nav-link text-lg text-center py-2 hover:text-red-500"
                  onClick={op_excel}
                >
                  Subir Excel
                </NavLink>
                <NavLink
                  to=""
                  className="nav-link text-lg text-center py-2 hover:text-red-500"
                  onClick={op_img}
                >
                  Subir Imagen
                </NavLink>
                <a
                  className="nav-link text-lg text-center py-2 text-blue-500 hover:text-red-500"
                  href="texto"
                  onClick={cerrarSecion}
                >
                  Cerrar Sesión
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {create === '1' && <CreateTable />}
      {excel === '1' && <UpExcel />}
      {img === '1' && <UpLoad />}
    </>
  );
};

export default Menu;
