import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button, Typography, Modal, Box, TextField, Grid, FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import config from '../../../config/config.js';
import { v4 as uuidv4 } from 'uuid';

const columns = [
  { id: 'FirstName', label: 'Nombre', minWidth: 150 },
  { id: 'LastName', label: 'Apellido', minWidth: 150 },
  { id: 'DNI', label: 'DNI', minWidth: 120 },
  { id: 'gender', label: 'Género', minWidth: 120 },
  { id: 'distance', label: 'Distancia', minWidth: 120 },
];

const EditModal = ({ open, onClose, corredor, onSave }) => {
  const [formData, setFormData] = useState(corredor || {});

  useEffect(() => {
    setFormData(corredor || {});
  }, [corredor]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', width: '70%', maxHeight: '80vh', overflowY: 'auto' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Editar Corredor</Typography>

        {/* Contenedor de campos en dos columnas */}
        <Grid container spacing={2}>
          {Object.keys(formData).map((key) => (
            <Grid item xs={12} sm={6} key={key}>
              <TextField
                name={key}
                label={key}
                value={formData[key] || ''}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }} // Asegura que las etiquetas no se solapen
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#666',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                    },
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>

        {/* Botón de Guardar */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave} 
            sx={{ width: '30%' }}
          >
            Guardar
          </Button>
        </Box>
        </Box>
        </Modal>
        );
  };

const AddCorredorModal = ({ open, onClose, onAdd }) => {
  const [newCorredor, setNewCorredor] = useState({
    FirstName: '', LastName: '', DNI: '', gender: '', distance: '', DateOfBirth: '', age:'', email: '', phone: '', Nacion: '', Procedencia: '', talle: '', talleShoe: '', running_team: '', contacto_emergencia: '', cel_emergencia: '', obra_social: '', RH: '', peso: '', frecuencia_cardiaca: '', medicamento: '', alergia: '', enfermedad_cardiaca: '', enfermedad_cronica: '', payment_price: '', nro_recibo: '', observaciones: '',
  });

  const handleChange = (e) => {
    // Filtrar solo números en el campo 'payment_price'
    if (e.target.name === 'payment_price') {
      // Usar una expresión regular para permitir solo números y el punto decimal
      const value = e.target.value.replace(/[^0-9.]/g, '');
      setNewCorredor({ ...newCorredor, [e.target.name]: value });
    } else {
      setNewCorredor({ ...newCorredor, [e.target.name]: e.target.value });
    }
  };

  const handleAdd = () => {
    const formId = uuidv4(); // Generar un ID único para este corredor
    const corredorConId = {
      ...newCorredor,
      formId, // Incluir formId en el objeto que se enviará
    };

    console.log('Datos enviados:', corredorConId); // Confirmar los datos en consola
    onAdd(corredorConId); // Llamar al manejador de adición en el componente padre

    // Reiniciar el formulario después de enviar los datos
    setNewCorredor({
      FirstName: '', LastName: '', DNI: '', gender: '', distance: '', DateOfBirth: '', age:'', email: '', phone: '', Nacion: '', Procedencia: '', talle: '', talleShoe: '', running_team: '', contacto_emergencia: '', cel_emergencia: '', obra_social: '', RH: '', peso: '', frecuencia_cardiaca: '', medicamento: '', alergia: '', enfermedad_cardiaca: '', enfermedad_cronica: '', payment_price: '', nro_recibo: '', observaciones: '',
    });
  };

  if (!open) return null; // Si el modal no está abierto, no renderizar nada

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '70%',
          maxHeight: '80vh',
          overflowY: 'auto',
          borderRadius: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
          Agregar nuevo corredor
          </Typography></Grid>

        {/* Contenedor de campos en dos columnas */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="FirstName"
              label="Nombre"
              value={newCorredor.FirstName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="LastName"
              label="Apellido"
              value={newCorredor.LastName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="DNI"
              label="DNI"
              value={newCorredor.DNI}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Género</InputLabel>
            <Select
              name="gender"
              label="Género"
              value={newCorredor.gender}
              onChange={handleChange}
            >
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Femenino">Femenino</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="distance"
              label="Distancia"
              value={newCorredor.distance}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="DateOfBirth"
              label="Fecha de Nacimiento"
              type="date"
              value={newCorredor.DateOfBirth}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true, // Asegura que la etiqueta no se solape
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="age"
              label="Edad al dia de la carrera"
              value={newCorredor.age}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              label="Email"
              value={newCorredor.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="phone"
              label="Teléfono"
              value={newCorredor.phone}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="Nacion"
              label="Nacionalidad"
              value={newCorredor.Nacion}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Provincia</InputLabel>
            <Select
              name="Procedencia"
              label="Provincia"
              value={newCorredor.Procedencia}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300, // Altura máxima de la lista desplegable
                    overflow: 'auto', // Habilita el desplazamiento
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              {[
                'Buenos Aires', 'CABA', 'Catamarca', 'Chaco', 'Chubut',
                'Córdoba', 'Corrientes', 'Entre Ríos', 'Formosa', 'Jujuy',
                'La Pampa', 'La Rioja', 'Mendoza', 'Misiones', 'Neuquén',
                'Río Negro', 'Salta', 'San Juan', 'San Luis', 'Santa Cruz',
                'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego',
              ].map((province) => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Talle de remera</InputLabel>
            <Select
              name="talle"
              label="Talle de remera"
              value={newCorredor.talle}
              onChange={handleChange}
            >
              <MenuItem value="XS">XXS</MenuItem>
                  <MenuItem value="XS">XS</MenuItem>
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="XL">XL</MenuItem>
                  <MenuItem value="XXL">XXL</MenuItem>
                  <MenuItem value="XXL">XXXL</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Talle de zapatillas</InputLabel>
            <Select
              name="talleShoe"
              label="Talle de Zapatillas"
              value={newCorredor.talleShoe}
              onChange={handleChange}
            >
              <MenuItem value="35/37">35/37</MenuItem>
              <MenuItem value="38/40">38/40</MenuItem>
              <MenuItem value="41/43">41/43</MenuItem>
              <MenuItem value="44/46">44/46</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="running_team"
              label="Running Team"
              value={newCorredor.running_team}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contacto_emergencia"
              label="Contacto emergencia"
              value={newCorredor.contacto_emergencia}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="cel_emergencia"
              label="Telefono de emergencia"
              value={newCorredor.cel_emergencia}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
          Ficha medica
          </Typography></Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="obra_social"
              label="Obra social"
              value={newCorredor.obra_social}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="RH"
              label="Grupo y factor sanguineo"
              value={newCorredor.RH}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="peso"
              label="Peso"
              value={newCorredor.peso}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="frecuencia_cardiaca"
              label="Frecuencia cardiaca"
              value={newCorredor.frecuencia_cardiaca}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="medicamento"
              label="Toma medicamento?"
              value={newCorredor.medicamento}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="alergia"
              label="Alergico?"
              value={newCorredor.alergia}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="enfermedad_cardiaca"
              label="Enfermedad_cardiaca"
              value={newCorredor.enfermedad_cardiaca}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="enfermedad_cronica"
              label="Enfermedad_cronica"
              value={newCorredor.enfermedad_cronica}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
          opciones de pago
          </Typography></Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="payment_price"
              label="Precio"
              value={newCorredor.payment_price}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              type="number"
              inputProps={{
                inputMode: 'numeric', // Ayuda a los dispositivos móviles a mostrar un teclado numérico
                pattern: '[0-9]*' // Define un patrón para aceptar solo números
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="nro_recibo"
              label="N° recibo"
              value={newCorredor.nro_recibo}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="observaciones"
              label="Observaciones"
              value={newCorredor.observaciones}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
        </Grid>

        {/* Botón centrado */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleAdd}
            sx={{ width: '30%' }}
          >
            Agregar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const InscriptosList = ({raceName}) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [selectedCorredor, setSelectedCorredor] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscriptos/${raceName}`);
      const formattedRows = response.data.map((row) => ({
        ...row,
        DateOfBirth: row.DateOfBirth ? new Date(row.DateOfBirth).toISOString().split('T')[0] : '', // Formateamos la fecha
      }));
      setRows(formattedRows);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }, [raceName]);

  useEffect(() => {
    if (raceName) {
      fetchData();
    }
  }, [raceName, fetchData]);

  const filteredRows = rows.filter((row) => {
    return (
      row.FirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.LastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.DNI.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = async (formId) => {
    try {
      const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscriptos/${raceName}/${formId}`);
      const data = response.data;
      data.DateOfBirth = data.DateOfBirth ? new Date(data.DateOfBirth).toISOString().split('T')[0] : ''; // Formateamos la fecha al mostrarla en el modal
      setSelectedCorredor(data);
      setEditOpen(true);
    } catch (error) {
      console.error('Error al obtener los datos del corredor:', error);
    }
  };

  const handleSave = async (updatedData) => {
    try {
      await axios.put(`${config.INSCRIPTION_URL}/api/inscriptos/${raceName}/${updatedData.formId}`, updatedData);
      setEditOpen(false);
      fetchData(); // Refresca los datos después de la edición
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
    }
  };

  const handleAdd = async (newCorredor) => {
    try {
      await axios.post(`${config.INSCRIPTION_URL}/api/inscriptos/${raceName}`, newCorredor);
      setAddOpen(false);
      alert('Corredor agregado exitosamente');
      fetchData(); // Refresca los datos después de agregar un nuevo corredor
    } catch (error) {
      console.error('Error al agregar el corredor:', error);
      alert('Hubo un error al agregar el corredor');
    }
  };

  return (
    <div className="container" style={{ marginTop: '70px' }}>
      <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: '0px 1px 10px black' }}>
        <Typography variant="h5" sx={{ padding: '20px' }}>
          Listado de inscriptos
        </Typography>

        {/* Agregar botón para abrir el modal de agregar corredor */}
        <Button variant="contained" color="error" onClick={() => setAddOpen(true)} sx={{ margin: '10px' }}>
          Agregar Corredor
        </Button>
        <br></br>
        {/* Agregar un TextField para el buscador */}
        <TextField
          label="Buscar Corredor"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            maxWidth: '50%',
            '& .MuiInputLabel-root': { color: '#333' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#666',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1976d2',
              },
            },
          }}
        />

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.formId}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                  ))}
                  <TableCell>
                    <Button variant="contained" color="error" onClick={() => handleEdit(row.formId)}>
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <EditModal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        corredor={selectedCorredor}
        onSave={handleSave}
      />
      <AddCorredorModal
        open={addOpen}
        onClose={() => setAddOpen(false)}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default InscriptosList;
